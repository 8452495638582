import React from "react";
import { Link, withPrefix } from "gatsby";
import { PageComponentProps } from "../../types/types";
import Layout from "../parts/Layout";
import { NextPageLink } from "../parts/NextPageLink";

// @ts-ignore
import IntervalsUnisonsDn from "../../images/svg-dn/Clairnote-intervals-unisons.svg";
// @ts-ignore
import IntervalsUnisonsSn from "../../images/svg-sn/Clairnote-intervals-unisons.svg";

// @ts-ignore
import IntervalsMinor2ndsDn from "../../images/svg-dn/Clairnote-intervals-minor-2nds.svg";
// @ts-ignore
import IntervalsMinor2ndsSn from "../../images/svg-sn/Clairnote-intervals-minor-2nds.svg";

// @ts-ignore
import IntervalsMajor2ndsDn from "../../images/svg-dn/Clairnote-intervals-major-2nds.svg";
// @ts-ignore
import IntervalsMajor2ndsSn from "../../images/svg-sn/Clairnote-intervals-major-2nds.svg";

// @ts-ignore
import IntervalsMinor3rdsDn from "../../images/svg-dn/Clairnote-intervals-minor-3rds.svg";
// @ts-ignore
import IntervalsMinor3rdsSn from "../../images/svg-sn/Clairnote-intervals-minor-3rds.svg";

// @ts-ignore
import IntervalsMajor3rdsDn from "../../images/svg-dn/Clairnote-intervals-major-3rds.svg";
// @ts-ignore
import IntervalsMajor3rdsSn from "../../images/svg-sn/Clairnote-intervals-major-3rds.svg";

// @ts-ignore
import IntervalsPerfect4thsDn from "../../images/svg-dn/Clairnote-intervals-perfect-4ths.svg";
// @ts-ignore
import IntervalsPerfect4thsSn from "../../images/svg-sn/Clairnote-intervals-perfect-4ths.svg";

// @ts-ignore
import IntervalsTritonesDn from "../../images/svg-dn/Clairnote-intervals-tritones.svg";
// @ts-ignore
import IntervalsTritonesSn from "../../images/svg-sn/Clairnote-intervals-tritones.svg";

// @ts-ignore
import IntervalsPerfect5thsDn from "../../images/svg-dn/Clairnote-intervals-perfect-5ths.svg";
// @ts-ignore
import IntervalsPerfect5thsSn from "../../images/svg-sn/Clairnote-intervals-perfect-5ths.svg";

// @ts-ignore
import IntervalsMinor6thsDn from "../../images/svg-dn/Clairnote-intervals-minor-6ths.svg";
// @ts-ignore
import IntervalsMinor6thsSn from "../../images/svg-sn/Clairnote-intervals-minor-6ths.svg";

// @ts-ignore
import IntervalsMajor6thsDn from "../../images/svg-dn/Clairnote-intervals-major-6ths.svg";
// @ts-ignore
import IntervalsMajor6thsSn from "../../images/svg-sn/Clairnote-intervals-major-6ths.svg";

// @ts-ignore
import IntervalsMinor7thsDn from "../../images/svg-dn/Clairnote-intervals-minor-7ths.svg";
// @ts-ignore
import IntervalsMinor7thsSn from "../../images/svg-sn/Clairnote-intervals-minor-7ths.svg";

// @ts-ignore
import IntervalsMajor7thsDn from "../../images/svg-dn/Clairnote-intervals-major-7ths.svg";
// @ts-ignore
import IntervalsMajor7thsSn from "../../images/svg-sn/Clairnote-intervals-major-7ths.svg";

// @ts-ignore
import IntervalsOctavesDn from "../../images/svg-dn/Clairnote-intervals-octaves.svg";
// @ts-ignore
import IntervalsOctavesSn from "../../images/svg-sn/Clairnote-intervals-octaves.svg";

import * as pageStyles from "../global-styles/page.module.css";
import { AudioVisualizer } from "../parts/AudioVisualizer/AudioVisualizer";

export const Intervals = (props: PageComponentProps) => {
  const { clairnoteName, sn, dn, urlDir } = props;
  const title = "Intervals";
  const description = `Illustration and discussion of intervals in ${clairnoteName} music notation. Intervals are represented more consistently and clearly than traditional notation.`;
  return (
    <Layout title={title} description={description} {...props}>
      <article className="page">
        <h1 className={`title is-3 ${pageStyles.pageTitle}`}>{title}</h1>
        <AudioVisualizer
          sn={sn}
          inputMode="intervals"
          hideNoteSeriesMenu={true}
        />
        <div>
          <p>
            In {clairnoteName} intervals are easy to identify. Each interval is
            visually distinct so it is simple to distinguish one interval from
            another. On the staff above, notice how each type of interval has a
            distinct appearance, and how that makes it easy to identify them and
            differentiate between them.
          </p>
          <p>
            If an interval spans an even number of semitones then both notes are{" "}
            {dn ? "solid" : "line notes"} or both are{" "}
            {dn ? "hollow" : "space notes"}. If it spans an odd number of
            semitones then one note is {dn ? "solid" : "a line note"} and the
            other is {dn ? "hollow" : "a space note"}. (This is the result of{" "}
            {clairnoteName}
            's{" "}
            <Link title="6-6 Pitch Pattern" to={urlDir + "staff/#six-six"}>
              6-6 pitch pattern
            </Link>
            .)
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              className="alignnone size-medium"
              src={dn ? IntervalsMinor3rdsDn : IntervalsMinor3rdsSn}
              alt={"Minor thirds in " + clairnoteName + " music notation"}
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              className="alignnone size-medium"
              src={dn ? IntervalsMajor3rdsDn : IntervalsMajor3rdsSn}
              alt={"Major thirds in " + clairnoteName + " music notation"}
            />
          </div>
          <p>
            For example, you can clearly see the difference between major thirds
            and minor thirds (shown above), which is not possible in traditional
            music notation. When compared with{" "}
            <a
              href="http://musicnotation.org/tutorials/intervals1.html"
              target="_blank"
              rel="external noopener noreferrer"
            >
              intervals in traditional music notation
            </a>
            , {clairnoteName} is more clear, consistent, and direct.
          </p>
          <p>
            Intervals are some of the most basic building blocks of music. Being
            able to clearly and consistently see the differences between them
            leads to a better awareness and understanding of music and common
            musical patterns.
          </p>
          <p>
            Improvising and playing by ear are skills that involve an intuitive
            awareness of the interval relationships between notes. Yet in
            traditional notation these relationships are obscured. How might
            one's ability to play by ear and improvise improve by using a
            notation system that made interval relationships more readily
            apparent?
          </p>
          <p>
            Below is a comprehensive illustration of all the common intervals
            (from unisons up through octaves) showing the consistent pattern in
            their appearance. The number of semitones spanned by each interval
            is given in parentheses.
          </p>
          <p>
            The following two PDF files contain illustrations of intervals in{" "}
            {clairnoteName} like those shown below, but arranged in a 4×3 grid
            pattern in order to highlight similarities in their appearance.
          </p>
          <p>
            <a
              href={withPrefix(
                "pdf/" +
                  (sn ? "sn" : "dn") +
                  "/Intervals-in-Clairnote-grouped-A.pdf"
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              Intervals in {clairnoteName} grouped A (PDF)
            </a>
            <br />
            <a
              href={withPrefix(
                "pdf/" +
                  (sn ? "sn" : "dn") +
                  "/Intervals-in-Clairnote-grouped-B.pdf"
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              Intervals in {clairnoteName} grouped B (PDF)
            </a>
          </p>
          <p>
            To practice reading intervals play the intervals game on the{" "}
            <Link to={urlDir + "learn/"} title="Learn">
              Learn
            </Link>{" "}
            page.
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? IntervalsUnisonsDn : IntervalsUnisonsSn}
              alt={"Unisons in " + clairnoteName + " music notation"}
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? IntervalsMinor2ndsDn : IntervalsMinor2ndsSn}
              alt={"Minor seconds in" + clairnoteName + " music notation"}
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? IntervalsMajor2ndsDn : IntervalsMajor2ndsSn}
              alt={"Major seconds in" + clairnoteName + " music notation"}
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? IntervalsMinor3rdsDn : IntervalsMinor3rdsSn}
              alt={"Minor thirds in" + clairnoteName + " music notation"}
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? IntervalsMajor3rdsDn : IntervalsMajor3rdsSn}
              alt={"Major thirds in" + clairnoteName + " music notation"}
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? IntervalsPerfect4thsDn : IntervalsPerfect4thsSn}
              alt={"Perfect fourths in " + clairnoteName + " music notation"}
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? IntervalsTritonesDn : IntervalsTritonesSn}
              alt={"Tritones in " + clairnoteName + " music notation"}
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? IntervalsPerfect5thsDn : IntervalsPerfect5thsSn}
              alt={"Perfect fifths in " + clairnoteName + " notation"}
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? IntervalsMinor6thsDn : IntervalsMinor6thsSn}
              alt={"Minor sixths in " + clairnoteName + " music notation"}
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? IntervalsMajor6thsDn : IntervalsMajor6thsSn}
              alt={"Major sixths in " + clairnoteName + " music notation"}
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? IntervalsMinor7thsDn : IntervalsMinor7thsSn}
              alt={"Minor sevenths in " + clairnoteName + " music notation"}
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? IntervalsMajor7thsDn : IntervalsMajor7thsSn}
              alt={"Major sevenths in " + clairnoteName + " music notation"}
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? IntervalsOctavesDn : IntervalsOctavesSn}
              alt={"Octaves in " + clairnoteName + " music notation"}
            />
          </div>

          <NextPageLink title="Chords" to={urlDir + "chords/"} />
        </div>
      </article>
    </Layout>
  );
};
