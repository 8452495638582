import { PageProps } from "gatsby";
import React from "react";

import { Intervals } from "../../components/pages/Intervals";

import { dnProps } from "../../js/utils";

const IntervalsPage = (props: PageProps) => (
  <Intervals {...{ ...dnProps, ...props }} />
);

export default IntervalsPage;
